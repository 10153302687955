import React from 'react';

import { Button, Container, Row, Col } from 'reactstrap';
import * as FontAwesome from 'react-icons/lib/fa';

import HelperComponent from './HelperComponent.js';

class PartProjekty extends HelperComponent {
  render() {
    var translations = this.setLanguage();
    var documentUrl = "./documents/" + translations.p2.dokument + ".pdf";

    return (
      <div className="textContainer" id="project" name="project">
      <section style={{background: 'url(./images/pageProjekty.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
        <Container>
        <Row>
          <Col md="12">
            <FontAwesome.FaFileTextO size={28} />
            <h1>{translations.p2.Headline}</h1>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p2.Intro1}</p>
            <p><b>{translations.p2.Intro2} &nbsp; | &nbsp; {translations.p2.Intro3} &nbsp; | &nbsp; {translations.p2.Intro4}</b></p>
          </Col>
          <Col md="12">
            <p>{translations.p2.Intro5}</p>
            <p>{translations.p2.Aplications.Text1}</p>
            <p>{translations.p2.Aplications.Text2}</p>
            { (this.state.lang === 'sk' || 
               this.state.lang === 'ru' || 
               this.state.lang === undefined 
               || this.state.lang === 'cz') &&
               <p>
                 <Button color="danger" href={ documentUrl } download><FontAwesome.FaPaperclip size={24} /> {translations.p8.Text2}</Button>
               </p>
            }
          </Col>
        </Row>
      </Container>
    </section>
    </div>
    );
  }
}

export default PartProjekty;