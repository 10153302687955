import React from 'react';

import { Container, Row, Col, Button, Collapse } from 'reactstrap';
import * as FontAwesome from 'react-icons/lib/fa';

import HelperComponent from './HelperComponent.js';

class PartIT extends HelperComponent {
  render() {
    var translations = this.setLanguage();
    var documentUrl = "./documents/" + translations.p1.dokument + ".pdf";

    return (
      <div className="textContainer" id="it" name="it">
      <section style={{background: 'url(./images/pageIKT.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
      <Container>
        <Row>
          <Col md="12">
            <FontAwesome.FaConnectdevelop size={28} />
            <h1>{translations.p1.Intro1}</h1>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p1.Intro2}</p>
            <p>{translations.p1.Intro5}</p>
            { (this.state.lang === 'sk' || 
               this.state.lang === 'ru' || 
               this.state.lang === undefined 
               || this.state.lang === 'cz') &&
               <p>
                 <Button color="danger" href={ documentUrl } download><FontAwesome.FaPaperclip size={24} /> {translations.p8.Text2}</Button>
                 &nbsp;
                 <Button color="warning" onClick={this.toggle}>{translations.p8.Text1}</Button>
               </p>
            }
          </Col>
        </Row>
          </Container>
        </section>
        <Collapse isOpen={this.state.collapse}>
        <Container>
        <Row>
          <Col md="12">
            <p>{translations.p1.Intro3}</p>
            <p>{translations.p1.Intro4}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FontAwesome.FaCubes size={28} />
            <h3>{translations.p1.Aplications.Headline}</h3>
          </Col>
          <Col md="8">
              <p>{translations.p1.Aplications.Text1}</p>
              <p>{translations.p1.Aplications.Text2}</p>
              <p>{translations.p1.Aplications.Text3}</p>
              <p>{translations.p1.Aplications.Text4}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FontAwesome.FaCloud size={28} />
            <h3>{translations.p1.Cloud.Headline}</h3>
          </Col>
          <Col md="8">
              <p>{translations.p1.Cloud.Text1}</p>
              <p>{translations.p1.Cloud.Text2}</p>
              <p>{translations.p1.Cloud.Text3}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FontAwesome.FaSitemap size={28} />
            <h3>{translations.p1.Outsourcing.Headline}</h3>
          </Col>
          <Col md="8">
              <p>{translations.p1.Outsourcing.Text1}</p>
              <p>{translations.p1.Outsourcing.Text2}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FontAwesome.FaStethoscope size={28} />
            <h3>{translations.p1.Analysis.Headline}</h3>
          </Col>
          <Col md="8">
              <p>{translations.p1.Analysis.Text1}</p>
              <p>{translations.p1.Analysis.Text2}</p>
              <p>{translations.p1.Analysis.Text3}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FontAwesome.FaCogs size={28} />
            <h3>{translations.p1.Automation.Headline}</h3>
          </Col>
          <Col md="8">
              <p>{translations.p1.Automation.Text1}</p>
              <p>{translations.p1.Automation.Text2}</p>
              <p>{translations.p1.Automation.Text3}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FontAwesome.FaLock size={28} />
            <h3>{translations.p1.Security.Headline}</h3>
          </Col>
          <Col md="8">
              <p>{translations.p1.Security.Text1}</p>
              <p>{translations.p1.Security.Text2}</p>
              <p>{translations.p1.Security.Text3}</p>
          </Col>
        </Row>
      </Container>
          </Collapse>
      </div>
    );
  }
}

export default PartIT;