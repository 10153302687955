import React from 'react';
import CookieConsent from 'react-cookie-consent';

import HelperComponent from './HelperComponent.js';

class CookieBanner extends HelperComponent {
  render() {
    var translations = this.setLanguage();

    return (
      <>
        <CookieConsent location="bottom" buttonText={translations.p11.Text12}
            cookieName="siteCookie" style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }} expires={150}
        >
          {translations.p11.Text11}
        </CookieConsent>
      </>
    );
  }
}

export default CookieBanner;