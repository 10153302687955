import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './css/index.css';
import App from './App';
import AppEko from './AppEko';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
<div>
    <BrowserRouter>
         <Route exact path="/" component={App}/>
    </BrowserRouter >
    <BrowserRouter>
        <Route exact path="/eko" component={AppEko}/>
    </BrowserRouter >
    <BrowserRouter>
        <Route exact path="/hia" component={AppEko}/>
    </BrowserRouter >
</div>
       , document.getElementById('root'));
registerServiceWorker();
