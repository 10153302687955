import React from 'react';

import { Container, Row, Col, Button } from 'reactstrap';

import logoSkola from './images/logoRuSkola.png';
import logoKlub from './images/logoKlubPVaLS.png';
import logoKrypto from './images/logoKrypto.png';
import logoUpratovanie from './images/logoUpratovanie.png';
import logoLio from './images/logoLio.png';
import logoKBN from './images/logoKBN.png';

import HelperComponent from './HelperComponent.js';

class PartPartners extends HelperComponent {
  render() {
    var translations = this.setLanguage();

    return (
      <div className="textContainer" id="partners" name="partners">
      <Container>
        <Row>
          <Col md="12">
            <h1>{translations.p8.Text8}</h1>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            HIA
          </Col>
          <Col md="2">
            <Button color="danger" href='/hia' target='blank'>{translations.p8.Text1}</Button>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            {translations.p7.Partner1_1}
            <br />
            {translations.p7.Partner1_2}
          </Col>
          <Col md="2">
            <Button color="danger" href='https://www.ruskaskola.sk/' target='blank'>{translations.p8.Text1}</Button>
          </Col>
          <Col md="2">
            <img src={logoSkola} className="" alt="logo" width='150' height='200' />
          </Col>
        </Row>
        <Row>
          <Col md="8" className="colorRed">
            {translations.p7.Partner3_1}
          </Col>
          <Col md="2">
            {/* {translations.p7.Partner3_2} */}
            <Button color="danger" href='http://www.kpvls.com/' target='blank'>{translations.p8.Text1}</Button>
          </Col>
          <Col md="2">
            <img src={logoKlub} className="" alt="logo" width='150' height='200' />
          </Col>
        </Row>
        <Row>
          <Col md="8">
            {translations.p3.Headline}
          </Col>
          <Col md="2">
            <Button color="danger" href='https://www.farmingmining.com/' target='blank'>{translations.p8.Text1}</Button>
          </Col>
          <Col md="2">
            <img src={logoKrypto} className="" alt="logo" width='192' height='192' />
          </Col>
        </Row>
        <Row>
          <Col md="8">
            {translations.p7.Partner2}
          </Col>
          <Col md="2">
            <Button color="danger" href='https://www.upratovanie.online/' target='blank'>{translations.p8.Text1}</Button>
          </Col>
          <Col md="2">
            <img src={logoUpratovanie} className="" alt="logo" width='200' height='40' />
          </Col>
        </Row>
        <Row>
          <Col md="8">
            {translations.p7.Partner4}
          </Col>
          <Col md="2">
            <Button color="danger" href='https://www.lio.company/' target='blank'>{translations.p8.Text1}</Button>
          </Col>
          <Col md="2">
            <img src={logoLio} className="" alt="logo" width='192' height='192' />
          </Col>
        </Row>
        <Row>
          <Col md="8">
            {translations.p7.Partner5}
          </Col>
          <Col md="2">
            <Button color="danger" href='https://www.kbn.sk/' target='blank'>{translations.p8.Text1}</Button>
          </Col>
          <Col md="2">
            <img src={logoKBN} className="" alt="logo" width='192' height='192' />
          </Col>
        </Row>
          </Container>
      </div>
    );
  }
}

export default PartPartners;