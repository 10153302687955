import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import HelperComponent from './HelperComponent.js';

class PartHome extends HelperComponent {
  render() {
    var translations = this.setLanguage();

    return (
      <div className="homeContainer">
      <section style={{background: 'url(./images/pageHome.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
      <Container>
        <Row>
          <Col md="12">
            <h1>{translations.p6.Intro1}<br />
            {translations.p6.Intro2}<br />
            {translations.p6.Intro3}</h1>
          </Col>
        </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default PartHome;