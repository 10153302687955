import React from 'react';
import scrollToComponent from 'react-scroll-to-component';

import './css/App.css';

import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap';
import * as FontAwesome from 'react-icons/lib/fa';

import HelperComponent from './HelperComponent.js';
import PartLogo from './PartLogo.js';
import PartHome from './PartHome.js';
import PartIT from './PartIT.js';
import PartSmartCities from './PartSmartCities.js';
import PartProjekty from './PartProjekty.js';
import PartAI from './PartAI.js';
import PartStartup from './PartStartup.js';
import PartReality from './PartReality.js';
import PartPartners from './PartPartners.js';
import PartContacts from './PartContacts.js';
import PartCertifications from './PartCertifications.js';
import PartFooter from './PartFooter.js';
import CookieBanner from './CookieConsent.js';

class App extends HelperComponent {
  render() {
    var translations = this.setLanguage();

    return (
      <div className="App">
      <div className='home logoContainer' ref={(section) => { this.Home = section; }}>
        <PartLogo />
      </div>{/*sticky="top"*/}
        <Navbar color="white" light expand="md" >
          <NavbarToggler onClick={this.toggle} aria-label="Menu" />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink onClick={() => { scrollToComponent(this.Home, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#home" aria-label="Home"><FontAwesome.FaHome size={28} /></NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => { scrollToComponent(this.It, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#it">{translations.p8.Text3}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {scrollToComponent(this.Cities, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#smartcities">{translations.p8.Text10}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {scrollToComponent(this.Project, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#project">{translations.p8.Text4}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {scrollToComponent(this.Ai, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#ai">{translations.p8.Text5}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {scrollToComponent(this.Startup, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#startup">{translations.p8.Text6}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {scrollToComponent(this.Reality, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#reality">{translations.p8.Text9}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {scrollToComponent(this.Contact, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#contact">{translations.p8.Text7}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {this.toggle(); this.setLang('sk')}} className="cursorHand">sk</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {this.toggle(); this.setLang('cz')}} className="cursorHand">cz</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {this.toggle(); this.setLang('ru')}} className="cursorHand">ru</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {this.toggle(); this.setLang('en')}} className="cursorHand">en</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <CookieBanner />
        <PartHome />
        <div className='it' ref={(section) => { this.It = section; }}>
          <PartIT />
        </div>
        <div className='smartcities' ref={(section) => { this.Cities = section; }}>
          <PartSmartCities />
        </div>
        <div className='project' ref={(section) => { this.Project = section; }}>
          <PartProjekty />
        </div>
        <div className='ai' ref={(section) => { this.Ai = section; }}>
          <PartAI />
        </div>
        <div className='startup' ref={(section) => { this.Startup = section; }}>
          <PartStartup />
        </div>
        <div className='reality' ref={(section) => { this.Reality = section; }}>
          <PartReality />
        </div>
        <div className='partners' ref={(section) => { this.Partners = section; }}>
          <PartPartners />
        </div>
        <div className='contact' ref={(section) => { this.Contact = section; }}>
          <PartContacts />
        </div>
        <div className='certifications' ref={(section) => { this.Certifications = section; }}>
          <PartCertifications />
        </div>
          <PartFooter />
      </div>
    );
  }
}

export default App;
