import React from 'react';
import scrollToComponent from 'react-scroll-to-component';

import './css/App.css';

import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap';
import * as FontAwesome from 'react-icons/lib/fa';

import HelperComponent from './HelperComponent.js';
import PartLogo from './PartLogo.js';
import PartHome from './PartHome.js';
import PartEko from './PartEko.js';
import PartEkoContacts from './PartEkoContacts.js';
import PartFooter from './PartFooter.js';
import CookieBanner from './CookieConsent.js';

class AppMain extends HelperComponent {
  render() {
    var translations = this.setLanguageSK();

    return (
      <div className="App">
      <div className='home logoContainer' ref={(section) => { this.Home = section; }}>
        <PartLogo />
      </div>{/*sticky="top"*/}
        <Navbar color="white" light expand="md" >
          <NavbarToggler onClick={this.toggle} aria-label="Menu" />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink onClick={() => { scrollToComponent(this.Home, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#home" aria-label="Home"><FontAwesome.FaHome size={28} /></NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {scrollToComponent(this.Contact, { offset: 0, align: 'top', duration: 2000}); this.toggle()}} href="#contact">{translations.p8.Text7}</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <CookieBanner />
        <PartEko />
        <div className='contact' ref={(section) => { this.Contact = section; }}>
          <PartEkoContacts />
        </div>
          <PartFooter />
      </div>
    );
  }
}

export default AppMain;
