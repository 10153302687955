import React from 'react';

import { Container, Row, Col, Button, Collapse } from 'reactstrap';
import * as FontAwesome from 'react-icons/lib/fa';

import HelperComponent from './HelperComponent.js';

class PartEko extends HelperComponent {
  render() {
    var translations = this.setLanguage();
    var documentUrl = "./documents/" + translations.p1.dokument + ".pdf";

    return (
      <div className="textContainer" id="it" name="it">
      <section style={{background: 'url(./images/pageIKT.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
      <Container>
        <Row>
          <Col md="12">
            <FontAwesome.FaConnectdevelop size={28} />
            <h1>HIA</h1>
          </Col>
        </Row>
          </Container>
        </section>
        <Container>
        <Row>
          <Col md="12">
            <p>{translations.p12.Text1}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <p>{translations.p12.Group1.Headline}</p>
          </Col>
          <Col md="8">
            <p>{translations.p12.Group1.Text1}</p>
            <p>{translations.p12.Group1.Text2}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <p>{translations.p12.Group2.Headline}</p>
          </Col>
          <Col md="8">
            <p>{translations.p12.Group2.Text1}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <p>{translations.p12.Group3.Headline}</p>
          </Col>
          <Col md="8">
            <p>{translations.p12.Group3.Text1}</p>
            <p>{translations.p12.Group3.Text2}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <p>{translations.p12.Group4.Headline}</p>
          </Col>
          <Col md="8">
            <p>{translations.p12.Group4.Text1}</p>
            <p>{translations.p12.Group4.Text2}</p>
            <p>{translations.p12.Group4.Text3}</p>
            <p>{translations.p12.Group4.Text4}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <p>{translations.p12.Group5.Headline}</p>
          </Col>
          <Col md="8">
            <p>{translations.p12.Group5.Text1}</p>
            <p>{translations.p12.Group5.Text2}</p>
            <p>{translations.p12.Group5.Text3}</p>
            <p>{translations.p12.Group5.Text4}</p>
            <p>{translations.p12.Group5.Text5}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <p>{translations.p12.Group6.Headline}</p>
          </Col>
          <Col md="8">
            <p>{translations.p12.Group6.Text1}</p>
            <p>{translations.p12.Group6.Text2}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <p>{translations.p12.Group7.Headline}</p>
          </Col>
          <Col md="8">
            <p>{translations.p12.Group7.Text1}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <p>{translations.p12.Group8.Headline}</p>
          </Col>
          <Col md="8">
            <p>{translations.p12.Group8.Text1}</p>
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}

export default PartEko;