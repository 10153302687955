import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import * as FontAwesome from 'react-icons/lib/fa';

import HelperComponent from './HelperComponent.js';

class PartEkoContacts extends HelperComponent {
  render() {
    var translations = this.setLanguage();

    return (
      <div className="contactContainer" id="contact" name="contact">
      <section style={{background: 'url(./images/pageContact.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
      <Container>
        <Row>
          <Col md="12">
            <h1>{translations.p7.Headline}</h1>
            <br />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p7.Intro1Eko}</p>
          </Col>
        </Row>
        <Row>
          <Col md="12" style={{padding: '0 0 1em 0'}}>
            <p><FontAwesome.FaEnvelope /> <a href="mailto:info@neptunsolutions.com">info@neptunsolutions.com</a></p>
          </Col>
          <Col md="12" style={{padding: '1em 0 0 0'}}>
            <p>NEPTUN SOLUTIONS, s.r.o.</p>
            <p className="colorRed"><b>Slovenská republika</b></p>
            <p><FontAwesome.FaPhone /> <a href="tel:00421948202199">+421 948 202 199</a></p>
            <p>Bajkalská 22 </p>
            <p>821 09, Bratislava II</p>
          </Col>
        </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default PartEkoContacts;