import React from 'react';

import { Button, Container, Row, Col, Collapse } from 'reactstrap';
import * as FontAwesome from 'react-icons/lib/fa';

import HelperComponent from './HelperComponent.js';

class PartSmartCities extends HelperComponent {
  render() {
    var translations = this.setLanguage();

    return (
      <div className="textContainer" id="smartcities" name="smartcities">
      <section style={{background: 'url(./images/pageSmartCities.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
        <Container>
        <Row>
          <Col md="12">
            <FontAwesome.FaCodepen  size={28} />
            <h1>{translations.p10.Headline}</h1>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p10.Intro1}</p>
            { (this.state.lang === 'sk' || 
               this.state.lang === 'ru' || 
               this.state.lang === undefined) &&
               <p><Button color="warning" onClick={this.toggle}>{translations.p8.Text1}</Button></p>
            }
            {this.state.lang === 'cz' &&
            <p>{translations.p7.Partner3_2}</p>
            }
          </Col>
        </Row>
          </Container>
        </section>
        <Collapse isOpen={this.state.collapse}>
        <Container>
        <Row>
          <Col md="12">
            <p>{translations.p10.Text1}</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p10.Text2}</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p10.Text3}</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p10.Text4}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <p>{translations.p10.Text5}</p>
          </Col>
          <Col md="8">
            <ul>
              <li>{translations.p10.Aplications.Text1}</li>
              <li>{translations.p10.Aplications.Text2}</li>
              <li>{translations.p10.Aplications.Text3}</li>
              <li>{translations.p10.Aplications.Text4}</li>
              <li>{translations.p10.Aplications.Text5}</li>
              <li>{translations.p10.Aplications.Text6}</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p10.Text6}</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p10.Text7}</p>
          </Col>
        </Row>
      </Container>
          </Collapse>
    </div>
    );
  }
}

export default PartSmartCities;