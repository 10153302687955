import React, { Component } from 'react';

import { Container, Row, Col } from 'reactstrap';

import logo from './images/logo.png';

class PartLogo extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col md="12">
            <img src={logo} className="col-xs-12 col-md-8 col-md-offset-2" alt="logo" />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default PartLogo;