import React, { Component } from 'react';
import cookie from 'react-cookies';

class HelperComponent extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
      width: 0,
      heightSet: true,
      height: 0,
      isOpen: false
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      lang: cookie.load('lang')
    });
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    let heightTmp = window.innerWidth * 0.6;
    this.setState({ width: window.innerWidth, height: heightTmp });
  }

  setLang(country) {
    cookie.save('lang', country, { path: '/' });
    this.setState({
      lang: country
    });
    window.location.reload();
   }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
      collapse: !this.state.collapse 
    });
  }
  
  render() {
    return (<></>);
  }

  setLanguage() {
    var translations;
    if (this.state.lang === undefined) {
      const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
      const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
      if (languageWithoutRegionCode === 'sk' || languageWithoutRegionCode === 'cz' || languageWithoutRegionCode === 'ru')
        translations = require('./lang/' + languageWithoutRegionCode + '.json');
      else {
        translations = require('./lang/sk.json');
        cookie.save('lang', 'sk', { path: '/' });
      }
    }
    else {
      translations = require('./lang/' + this.state.lang + '.json');
    }
    return translations;
  }

  setLanguageSK() {
    var translations;
    translations = require('./lang/sk.json');
    cookie.save('lang', 'sk', { path: '/' });
    return translations;
  }
}

export default HelperComponent;
