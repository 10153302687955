import React from 'react';

import { Container, Row, Col } from 'reactstrap';
// import * as FontAwesome from 'react-icons/lib/fa';
import logoNBU from './images/logoNBU.png';
import logoEU from './images/logoEU.png';
import logoNATO from './images/logoNATO.png';

import HelperComponent from './HelperComponent.js';

class PartCertifications extends HelperComponent {
  render() {
    var translations = this.setLanguage();

    return (
      <div className="contactContainer" id="certifications" name="certifications">
      <section style={{background: 'url(./images/pageCertificates.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
      <Container>
        <Row>
          <Col md="12">
            <h1>Certifikáty</h1>
            <br />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>Máme potvrdenie o priemyselnej bezpečnosti vydané Národným bezpečnostným úradom na oboznamovanie sa s utajovanou skutočnosťou</p>
            <p><a className="colorRed" target="_blanc" href="https://www.nbu.gov.sk/ochrana-utajovanych-skutocnosti/bezpecnostne-previerky/podnikatelia/zoznam-podnikatelov-s-potvrdenimzoznamy-drzitelov-potvrdeni-htm/index.html"><u>Zoznamy držiteľov potvrdení</u></a></p>
          </Col>
        </Row>
        <Row className='distpart'>
          <Col md="6" style={{padding: '1em 0 0 0'}}>
            <p>Slovenská republika</p>
            <p className="colorRed"><b>Prísne Tajné</b></p>
          </Col>
          <Col md="6" style={{padding: '1em 0 0 0'}}>
            <img src={logoNBU} className="" alt="logo NBU SR" width='200' height='156' />
          </Col>
        </Row>
        <Row className='distpart'>
          <Col md="6" style={{padding: '1em 0 0 0'}}>
            <p>European Union</p>
            <p className="colorRed"><b>EU TOP SECRET</b></p>
          </Col>
          <Col md="6" style={{padding: '1em 0 0 0'}}>
            <img src={logoEU} className="" alt="logo EU" width='200' height='133' />
          </Col>
        </Row>
        <Row className='distpart'>
          <Col md="6" style={{padding: '1em 0 0 0'}}>
            <p>The North Atlantic Treaty Organization</p>
            <p className="colorRed"><b>Cosmic Top Secret</b></p>
          </Col>
          <Col md="6" style={{padding: '1em 0 0 0'}}>
            <img src={logoNATO} className="" alt="logo NATO" width='200' height='100' />
          </Col>
        </Row>
        <Row className='distpart'>
          <Col md="12" style={{padding: '1em 0 0 0'}}>
            <p>Spoločnosť je zapísaná v <b>Zozname hospodárskych subjektov</b> vedenom Úradom pre verejné obstarávanie SR pod číslom <a className="colorRed" target="_blanc" href="https://www.uvo.gov.sk/zoznam-hospodarskych-subjektov/detail/48267?page=1&limit=20&sort=nazov&sort-dir=ASC&ext=0&ico=51762102&nazov=NEPTUN+solutions&obec=&registracneCislo="><u>2019/2-PO-E9890</u></a>.</p>
          </Col>
        </Row>
        <Row className='distpart'>
          <Col md="12" style={{padding: '1em 0 0 0'}}>
            <p>Spoločnosť je zapísaná v <b>Registri partnerov verejného sektora</b> vedenom Ministerstvom spravodlivosti SR pod číslom <a className="colorRed" target="_blanc" href="https://rpvs.gov.sk/rpvs/Partner/Partner/Detail/24454"><u>24454</u></a>.</p>
          </Col>
        </Row>
        <Row className='distpart'>
          <Col md="12" style={{padding: '1em 0 0 0'}}>
            <p>Naša spoločnosť disponuje Osvedčením o spôsobilosti vykonávať výskum a vývoj, ktoré bolo vydané Ministerstvom školstva, vedy, výskumu a športu SR pod číslom <a className="colorRed" target="_blanc" href="https://www.skcris.sk/portal/register-organizations?p_p_id=organisationSearchResult_WAR_cvtiappweb&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&p_p_col_id=column-3&p_p_col_pos=2&p_p_col_count=3&_organisationSearchResult_WAR_cvtiappweb_javax.portlet.action=organizationgodetail&guid=cfOrg_8031&lang=sk_SK"><u>2021/11967:2-D1230</u></a>.</p>
          </Col>
        </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default PartCertifications;