import React from 'react';

import { Container, Row, Col, Button, Collapse } from 'reactstrap';
import * as FontAwesome from 'react-icons/lib/fa';

import HelperComponent from './HelperComponent.js';

class PartStartup extends HelperComponent {
  render() {
    var translations = this.setLanguage();
    var documentUrl = "./documents/" + translations.p5.dokument + ".pdf";
    
    return (
      <div className="textContainer" id="startup" name="startup">
      <section style={{background: 'url(./images/pageStartup.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
      <Container>
        <Row>
          <Col md="12">
            <FontAwesome.FaFortAwesome size={28} />
            <h1>{translations.p5.Headline}</h1>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p5.Intro1}</p>
            <p>{translations.p5.Intro2}</p>
            <p>{translations.p5.Intro3}</p>
            <p>{translations.p5.Intro4}</p>
            { (this.state.lang === 'sk' || 
               this.state.lang === 'ru' || 
               this.state.lang === undefined 
               || this.state.lang === 'cz') &&
               <p>
                 <Button color="danger" href={ documentUrl } download><FontAwesome.FaPaperclip size={24} /> {translations.p8.Text2}</Button>
                 &nbsp;
                 <Button color="warning" onClick={this.toggle}>{translations.p8.Text1}</Button>
               </p>
            }
          </Col>
        </Row>
          </Container>
        </section>
        <Collapse isOpen={this.state.collapse}>
        <Container>
        <Row>
          <Col md="4">
            <FontAwesome.FaQuestionCircleO size={28} />
            <h3>{translations.p5.Part1.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p5.Part1.Text1}</p>
            <p>{translations.p5.Part1.Text2}</p>
            <p>{translations.p5.Part1.Text3}</p>
            <p>{translations.p5.Part1.Text4}</p>
            <p>{translations.p5.Part1.Text5}</p>
            <p>{translations.p5.Part1.Text6}</p>
            <p>{translations.p5.Part1.Text7}</p>
            <p>{translations.p5.Part1.Text8}</p>
            <p>{translations.p5.Part1.Text9}</p>
            <p>{translations.p5.Part1.Text10}</p>
            <p>{translations.p5.Part1.Text11}</p>
            <p>{translations.p5.Part1.Text12}</p>
            <p>{translations.p5.Part1.Text13}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p5.Part2.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p5.Part2.Text1}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p5.Part3.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p5.Part3.Text1}</p>
            <p>{translations.p5.Part3.Text2}</p>
            <p>{translations.p5.Part3.Text3}</p>
            <p>{translations.p5.Part3.Text4}</p>
            <p>{translations.p5.Part3.Text5}</p>
            <p>{translations.p5.Part3.Text6}</p>
            <p>{translations.p5.Part3.Text7}</p>
            <p>{translations.p5.Part3.Text8}</p>
            <p>{translations.p5.Part3.Text9}</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FontAwesome.FaMapSigns size={28} />
            <h2>{translations.p5.Part4.Headline}</h2>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FontAwesome.FaArrowsH size={28} />
            <h3>{translations.p5.Part5.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p5.Part5.Text1}</p>
            <p>{translations.p5.Part5.Text2}</p>
            <p>{translations.p5.Part5.Text3}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FontAwesome.FaClockO size={28} />
            <h3>{translations.p5.Part6.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p5.Part6.Text1}</p>
            <p>{translations.p5.Part6.Text2}</p>
            <p>{translations.p5.Part6.Text3}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FontAwesome.FaBullhorn size={28} />
            <h3>{translations.p5.Part7.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p5.Part7.Text1}</p>
            <p>{translations.p5.Part7.Text2}</p>
            <p>{translations.p5.Part7.Text3}</p>
            <p>{translations.p5.Part7.Text4}</p>
            <p>{translations.p5.Part7.Text5}</p>
            <p>{translations.p5.Part7.Text6}</p>
            <p>{translations.p5.Part7.Text7}</p>
            <p>{translations.p5.Part7.Text8}</p>
          </Col>
        </Row>
      </Container>
        </Collapse>
      </div>
    );
  }
}
export default PartStartup;