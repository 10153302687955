import React from 'react';

import { Container, Row, Col, Button, Collapse } from 'reactstrap';
import * as FontAwesome from 'react-icons/lib/fa';

import HelperComponent from './HelperComponent.js';

class PartAI extends HelperComponent {
  render() {
    var translations = this.setLanguage();
    var documentUrl = "./documents/" + translations.p4.dokument + ".pdf";

    return (
      <div className="textContainer" id="ai" name="ai">
      <section style={{background: 'url(./images/pageAI.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
        <Container>
        <Row>
          <Col md="12">
            <FontAwesome.FaJsfiddle  size={28} />
            <h1>{translations.p4.Headline}</h1>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p4.Intro1}</p>
            <p>{translations.p4.Intro2}</p>
            { (this.state.lang === 'sk' || 
               this.state.lang === 'ru' || 
               this.state.lang === undefined 
               || this.state.lang === 'cz') &&
               <p>
                 <Button color="danger" href={ documentUrl } download><FontAwesome.FaPaperclip size={24} /> {translations.p8.Text2}</Button>
                 &nbsp;
                 <Button color="warning" onClick={this.toggle}>{translations.p8.Text1}</Button>
               </p>
            }
          </Col>
        </Row>
          </Container>
        </section>
        <Collapse isOpen={this.state.collapse}>
        <Container>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part1.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part1.Text1}</p>
            <p>{translations.p4.Part1.Text2}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part2.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part2.Text1}</p>
            <p>{translations.p4.Part2.Text2}</p>
            <ul>
              <li>{translations.p4.Part2.list.Text1}</li>
              <li>{translations.p4.Part2.list.Text2}</li>
              <li>{translations.p4.Part2.list.Text3}</li>
              <li>{translations.p4.Part2.list.Text4}</li>
              <li>{translations.p4.Part2.list.Text5}</li>
              <li>{translations.p4.Part2.list.Text6}</li>
              <li>{translations.p4.Part2.list.Text7}</li>
              <li>{translations.p4.Part2.list.Text8}</li>
              <li>{translations.p4.Part2.list.Text9}</li>
              <li>{translations.p4.Part2.list.Text10}</li>
              <li>{translations.p4.Part2.list.Text11}</li>
              <li>{translations.p4.Part2.list.Text12}</li>
              <li>{translations.p4.Part2.list.Text13}</li>
              <li>{translations.p4.Part2.list.Text14}</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part14.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part14.Text1}</p>
            <ul>
              <li>{translations.p4.Part14.Text2}</li>
              <li>{translations.p4.Part14.Text3}</li>
              <li>{translations.p4.Part14.Text4}</li>
              <li>{translations.p4.Part14.Text5}</li>
              <li>{translations.p4.Part14.Text6}</li>
              <li>{translations.p4.Part14.Text7}</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part3.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part3.Text1}</p>
            <p>{translations.p4.Part3.Text2}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part4.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part4.Text1}</p>
            <p>{translations.p4.Part4.Text2}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part5.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part5.Text1}</p>
            <p>{translations.p4.Part5.Text2}</p>
            <p>{translations.p4.Part5.Text3}</p>
            <p>{translations.p4.Part5.Text4}</p>
            <p>{translations.p4.Part5.Text5}</p>
            <p>{translations.p4.Part5.Text6}</p>
            <p>{translations.p4.Part5.Text7}</p>
            <p>{translations.p4.Part5.Text8}</p>
            <p>{translations.p4.Part5.Text9}</p>
            <p>{translations.p4.Part5.Text10}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part6.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part6.Text1}</p>
            <p>{translations.p4.Part6.Text2}</p>
            <p>{translations.p4.Part6.Text3}</p>
            <p>{translations.p4.Part6.Text4}</p>
            <p>{translations.p4.Part6.Text5}</p>
            <p>{translations.p4.Part6.Text6}</p>
            <p>{translations.p4.Part6.Text7}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part7.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part7.Text1}</p>
            <p>{translations.p4.Part7.Text2}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part8.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part8.Text1}</p>
            <p>{translations.p4.Part8.Text2}</p>
            <p>{translations.p4.Part8.Text3}</p>
            <p>{translations.p4.Part8.Text4}</p>
            <p>{translations.p4.Part8.Text5}</p>
            <p>{translations.p4.Part8.Text6}</p>
            <p>{translations.p4.Part8.Text7}</p>
            <p>{translations.p4.Part8.Text8}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part9.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part9.Text1}</p>
            <p>{translations.p4.Part9.Text2}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part10.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part10.Text1}</p>
            <p>{translations.p4.Part10.Text2}</p>
            <p>{translations.p4.Part10.Text3}</p>
            <p>{translations.p4.Part10.Text4}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part13.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part13.Text1}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part12.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part12.Text1}</p>
            <p>{translations.p4.Part12.Text2}</p>
            <p>{translations.p4.Part12.Text3}</p>
            <p>{translations.p4.Part12.Text4}</p>
            <p>{translations.p4.Part12.Text5}</p>
            <p>{translations.p4.Part12.Text6}</p>
            <p>{translations.p4.Part12.Text7}</p>
            <p>{translations.p4.Part12.Text8}</p>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>{translations.p4.Part11.Headline}</h3>
          </Col>
          <Col md="8">
            <p>{translations.p4.Part11.Text1}</p>
            <p>{translations.p4.Part11.Text2}</p>
            <p>{translations.p4.Part11.Text3}</p>
            <p>{translations.p4.Part11.Text4}</p>
            <p>{translations.p4.Part11.Text5}</p>
          </Col>
        </Row>
      </Container>
          </Collapse>
      </div>
    );
  }
}

export default PartAI;