import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import * as FontAwesome from 'react-icons/lib/fa';

import HelperComponent from './HelperComponent.js';

class PartReality extends HelperComponent {
  render() {
    var translations = this.setLanguage();

    return (
      <div className="textContainer" id="reality" name="reality">
      <section style={{background: 'url(./images/pageReality.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
        <Container>
        <Row>
          <Col md="12">
            <FontAwesome.FaHome size={28} />
            <h1>{translations.p9.Headline}</h1>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p9.Intro1}</p>
            <p>{translations.p9.Intro2}</p>
          </Col>
          <Col md="12">
            <p><b>{translations.p9.Aplications.Text1} &nbsp; | &nbsp; {translations.p9.Aplications.Text2} &nbsp; | &nbsp; {translations.p9.Aplications.Text3} &nbsp; | &nbsp; {translations.p9.Aplications.Text4}</b></p>
            <p><b>{translations.p9.Aplications.Text5} &nbsp; | &nbsp; {translations.p9.Aplications.Text6} &nbsp; | &nbsp; {translations.p9.Aplications.Text7}</b></p>
          </Col>
          <Col md="12">
            <p>{translations.p9.Intro3} <FontAwesome.FaEnvelope /> <a href="mailto:info@neptunsolutions.com"style={{color: '#faac06'}}>info@neptunsolutions.com</a></p>
          </Col>
        </Row>
      </Container>
    </section>
    </div>
    );
  }
}

export default PartReality;