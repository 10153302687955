import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import * as FontAwesome from 'react-icons/lib/fa';

import HelperComponent from './HelperComponent.js';

class PartContact extends HelperComponent {
  render() {
    var translations = this.setLanguage();

    return (
      <div className="contactContainer" id="contact" name="contact">
      <section style={{background: 'url(./images/pageContact.jpg) center top no-repeat', backgroundSize: 'contain', minHeight: this.state.height}}>
      <Container>
        <Row>
          <Col md="12">
            <h1>{translations.p7.Headline}</h1>
            <br />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p>{translations.p7.Intro1}</p>
          </Col>
        </Row>
        <Row>
          <Col md="12" style={{padding: '0 0 1em 0'}}>
            <p><FontAwesome.FaEnvelope /> <a href="mailto:info@neptunsolutions.com">info@neptunsolutions.com</a></p>
          </Col>
          <Col md="6" style={{padding: '1em 0 0 0'}}>
            <p>NEPTUN SOLUTIONS, s.r.o.</p>
            <p className="colorRed"><b>Slovenská republika</b></p>
            <p><FontAwesome.FaPhone /> <a href="tel:00421948202199">+421 948 202 199</a></p>
            <p>Bajkalská 22 </p>
            <p>821 09, Bratislava II</p>
          </Col>
          {/* <Col md="4" style={{padding: '1em 0 0 0'}}>
            <p>Нептун Солыошнз</p>
            <p className="colorRed"><b>{translations.p7.Address3_1}</b></p>
            <p><FontAwesome.FaPhone /> <a href="tel:0079160066153">+7 (916) 006-61-53</a></p>
            <p><b>{translations.p7.Address3_2}</b></p>
            <p><b>{translations.p7.Address3_3}</b></p>
          </Col> */}
          <Col md="6" style={{padding: '1em 0 0 0'}}>
            <p>NEPTUN SOLUTIONS, s.r.o.</p>
            <p className="colorRed"><b>Česká republika</b></p>
            <p><FontAwesome.FaPhone /> <a href="tel:00420728437739">+420 728 437 739</a></p>
            <p>U Půjčovny 4</p>
            <p>11000, Praha 1</p>
          </Col>
        </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default PartContact;